@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100..900&display=swap');

html {
  overflow-y:auto
}

body {
  background-color: #282c34;
  font-family: "Noto Sans Mono", monospace;
  width: 100%;
  overflow-y:auto;
  /* color: rgb(175, 175, 175);  */
  color: #abb2bf;
}

p::selection, a::selection, button::selection, h1::selection, img::selection, h2::selection, h3::selection, h4::selection, span::selection {
  background: #000;
  
}

p::selection, a::selection, button::selection, img::selection, h1::selection, h2::selection, h3::selection, h4::selection, span::selection{
  color: #fff;
 }


 .mono-bold {
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.mono-semibold {
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 550;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.mono-normal {
  font-family: "Noto Sans Mono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.__react_modal_image__header {

  background-color: rgba(0, 0, 0, 0.0) !important;
  padding: 10px;
}
.__react_modal_image__modal_content {
}


.__react_modal_image__modal_content img, 
  .__react_modal_image__modal_content svg {
    padding: 100px !important;
    background-color: transparent;
  }



  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-text {
    font-size: 24px;
    font-weight: bold;
  }